import React, { useState, useEffect, useRef } from 'react'

let observer
const listeners = new WeakMap()

const getObserver = () => {
  if (!observer) {
    observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        const cb = listeners.get(entry.target)
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          observer.unobserve(entry.target)
          listeners.delete(entry.target)
          cb()
        }
      }
    })
  }
  return observer
}

const observe = (el, cb) => {
  const observer = getObserver()
  observer.observe(el)
  listeners.set(el, cb)
  return () => {
    observer.unobserve(el)
    listeners.delete(el)
  }
}

const Image = ({ fluid, className, classNameGhost, classNamePlaceholder }) => {
  const [loaded, setLoaded] = useState(false)
  const [visible, setVisible] = useState(false)
  const ref = useRef()

  useEffect(() => observe(ref.current, () => setVisible(true)), [])

  return (
    <div className={className} ref={ref}>
      <div
        className={classNameGhost}
        style={{ paddingTop: `${100 / fluid.aspectRatio}%` }}
      />
      {visible && (
        <picture>
          <source
            type="image/webp"
            srcSet={fluid.srcSetWebp}
            sizes={fluid.sizes}
          />
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
          <img
            sizes={fluid.sizes}
            src={fluid.src}
            srcSet={fluid.srcSet}
            alt=""
            onLoad={() => setLoaded(true)}
          />
          {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
        </picture>
      )}
      <img
        className={classNamePlaceholder}
        src={fluid.base64}
        style={{ opacity: loaded ? 0 : 1, transition: 'opacity 0.5s' }}
        alt=""
      />
    </div>
  )
}

export default Image
